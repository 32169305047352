var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ground-cameras"},[_c('div',{staticClass:"title-box"},[_vm._m(0),_c('el-button',{attrs:{"type":"info"},on:{"click":function($event){return _vm.$router.push({ path: '/data', query: { tabsIndex: 1, time: _vm.time } })}}},[_vm._v(" Show More ")])],1),_c('el-image',{staticClass:"ground-cameras__img",attrs:{"src":_vm.detail.latelyGroundPhotoList && _vm.detail.latelyGroundPhotoList[0]
        ? _vm.detail.latelyGroundPhotoList[0].filePath
        : '',"preview-src-list":[
      _vm.detail.latelyGroundPhotoList && _vm.detail.latelyGroundPhotoList[0]
        ? _vm.detail.latelyGroundPhotoList[0].filePath
        : ''
    ],"fit":"cover"}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-box__icon"},[_c('img',{attrs:{"src":require("../../../assets/icons/dashboard/Ground Cameras.png")}}),_c('span',[_vm._v("Ground Cameras")])])
}]

export { render, staticRenderFns }